<div class="main-content mt-[65px] lg:mt-[82px]">
    <div *ngIf="iconSet" id="clientScopeApp" class="px-[15px] lg:pl-[10rem] pb-3 lg:pt-3">
        <div *ngIf="hasError; else showDetails">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 cumulative-graph ">
                            <div>
                                <h3 class="text-center">{{errorMessage}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-template #showDetails>
            <div class="row mh-42 mx-0">
                <div class="col-12 px-0">
                    <div class="lg:mt-4 bread-crumb-container grid lg:col-12 lg:flex mr-[15px] lg:mx-0">
                        <div class="col-12 px-0 lg:contents">
                            <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                                <a class="custom-breadcrumb__text" attr.data-cy="breadcrumb{{breadcrumb.type}}"
                                    routerLinkActive="active" [routerLink]="breadcrumb.route"
                                    [queryParams]="breadcrumbDataService.getQueryParamsForBreadcrumbs(breadcrumb)"
                                    [title]="breadcrumb.name" (click)="breadcrumbClick(breadcrumb)">
                                    {{breadcrumb.name}}</a>
                                <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
                            </div>
                        </div>
                        <app-auto-adjusting-dropdown [selected]="assetName"
                            [dataForBreadcrumbs]="assetsDataForBreadcrumb" (goToSelected)="goToSelectedAsset($event)"
                            [queryParams]="queryAssetMasterId" (sendWidth)="getWidth($event)"
                            [valueProperty]="'id'" [nameProperty] = "'name'" [type]="'Assets'"
                            data-cy="assetDropdown"></app-auto-adjusting-dropdown>
                    </div>
                </div>
            </div>
            <!-- NOTE: Hide reporting period dropdown for now, and will add 
                back in when we have asset type specific time series data -->
            <!-- <div class="row mh-42">
                <div class="col-12">
                    <div class="d-flex">
                        <div class="asofDates d-flex ml-auto">
                            <div>
                                <img src="{{iconSet['icon.calendar']}}" class="asofDates__icon" />
                            </div>
                            <select name="asOfDateddl" class="asofDates__as-custom-select" id="asOfDateSel"
                                [(ngModel)]="selectedAsOfDate" (change)='updateAsOfDate($event.target.value)'>
                                <option *ngFor="let date of asOfDropdownData" [value]="date">
                                    {{date | dateFormat : 'MM/dd/yyyy'}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div> -->
            <div *ngIf="showAssetNotFound; else showAssetDetails">
                <div class="row">
                    <div class="col-12">
                        <div class="mt-3 cumulative-graph ">
                            <div>
                                <h3 class="text-center">Asset Not Found</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #showAssetDetails>
                <div class="row mh-61 mx-0 mt-4 lg:mt-0 grid grid-cols-1 md:grid-cols-3 lg:px-0">
                    <div class="md:col-span-2 lg:px-0">
                        <div>
                            <div class="asset-title" data-cy="assetTitle">{{assetName}}</div>
                            <span *ngIf="assetSummary.showAssetUrl" class="text-md md:text-lg"><a href="{{assetSummary.assetURL}}"
                                    target="_blank" (click)="activityTracking.navigateToAssetUrl('asset dashboard', queryAssetMasterId, assetName, assetSummary.assetURL)"
                                    >{{assetSummary.assetURL}}</a></span>
                        </div>
                    </div>
                    <div class="md:col-span-1">
                        <div class="snap-shot ml-auto">
                            <button (click)="getSnapshot();" data-cy="snapshotButton"><img
                                    src="{{iconSet['icon.snapshot']}}" />Snapshot</button>
                        </div>
                    </div>
                </div>

                <app-asset-summary [assetType]="assetType" [assetData]="assetData" [title]="assetSummary.title" [summary]="assetSummary.summary"
                    [logo]="assetSummary.logo" [slidingImgs]="assetSummary.slidingImgs"></app-asset-summary>
                <app-financial-summary [assetType]="assetType" [iconSet]="iconSet" [selectedAsOfDate]="selectedAsOfDate"
                    [financialData]="assetData"></app-financial-summary>
                <ng-container *ngIf="assetType === 'Real Estate' && assetSummary.showMap">
                    <app-map-widget [position]="assetSummary.position"></app-map-widget>
                </ng-container>
            </ng-template>

        </ng-template>
    </div>
</div>