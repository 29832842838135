import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestorRepository } from 'src/app/services/repositories/investor.repository';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-no-access-error',
  templateUrl: './no-access-error.component.html',
  styleUrls: ['./no-access-error.component.scss']
})
export class NoAccessErrorComponent implements OnInit {
  assignedInvestorIds: number[];
  @Output() updateInvestor: EventEmitter<number> = new EventEmitter();

  constructor(
    private router: Router,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private investorRepository: InvestorRepository
  ) { }

  ngOnInit(): void {
    if (this.activeRoute.snapshot.params['investorId']) {
      this.assignedInvestorIds = [this.activeRoute.snapshot.params['investorId']];
    }
    if (!this.assignedInvestorIds || this.assignedInvestorIds.length == 0) {
      this.assignedInvestorIds = this.userService.getInvestorIds();
    }
  }

  redirectUser(ids: number[]) {
    if (ids && ids.length > 0) {
      this.investorRepository.setActiveEntity(ids[0]);
      this.router.navigate(['/dashboard'], {
        queryParams: { investorid: ids[0] },
      });
      this.updateInvestor.emit(ids[0]);
    }
  }
}
