<div class="main-content mt-[65px] lg:mt-[82px]">
  <div *ngIf="iconSet" class="px-[15px] lg:pl-[10rem] pb-3 lg:pt-3 product-container">
    <div *ngIf="hasError; else showDetails">
      <div *ngIf="showNoAccessError; else showErrorMessage">
        <app-no-access-error></app-no-access-error>
      </div>
      <ng-template #showErrorMessage>
        <div class="row">
          <div class="col-12">
            <div class="mt-3 cumulative-graph ">
              <div>
                <h3 class="text-center">{{errorMessage}}</h3>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #showDetails>
      <ng-container data-cy="holdingDashboard">
        <div class="row" style="min-height: 42px">
          <div class="md:mt-4 product-container__bread-crumb grid md:col-12 md:flex md:px-[15px]">
            <div class="col-12 md:contents">
              <div *ngFor="let breadcrumb of breadcrumbs" class="custom-breadcrumb">
                <a class="custom-breadcrumb__text" attr.data-cy="breadcrumb{{breadcrumb.type}}"
                  routerLinkActive="active" [routerLink]="breadcrumb.route"
                  [queryParams]="breadcrumbDataService.getQueryParamsForBreadcrumbs(breadcrumb)"
                  (click)="breadcrumbClick(breadcrumb)" [title]="breadcrumb.name">
                  {{breadcrumb.name}}
                </a>
                <mat-icon class="custom-breadcrumb__icon">chevron_right</mat-icon>
              </div>
            </div>
            <app-auto-adjusting-dropdown [selected]="holdingNameForBreadcrumb"
              [dataForBreadcrumbs]="dealAndHoldingsDataForBreadcrumb" (goToSelected)="goToSelectedHoldings($event)"
              [queryParams]="queryParamsHoldingId" (sendWidth)="getWidth($event)" class="px-[13px] md:px-0"
              [valueProperty]="'id'" [nameProperty]="'name'" [groupProperty]="'subEntities'" [type]="'Holdings'"
              data-cy="holdingsDropdown"></app-auto-adjusting-dropdown>
          </div>
        </div>
        <div class="grid grid-cols-1 mt-4 md:mt-0">
          <div class="flex col-span-2">
            <div class="asofDates flex ml-auto">
              <div>
                <img src="{{iconSet['icon.calendar']}}" class="asofDates__icon" />
              </div>
              <select name="asOfDateddl" class="asofDates__as-custom-select pointer" id="asOfDateSel"
                [(ngModel)]="selectedAsOfDate" (change)='updateAsOfDate($event.target.value)'
                [ngClass]="{'error-text' : isHoldingDataUnavailable && valReportingDate}" data-cy="asOfDateDropdown">
                <option *ngFor="let date of asOfDropdownData" [value]="date" data-cy="selectedDate"
                  [disabled]="isHoldingDataUnavailable && valReportingDate == date" class="normal-select-text">
                  {{date | dateFormat : 'MM/dd/yyyy'}}</option>
              </select>
            </div>
          </div>
          <div class="snap-shot mr-3" *ngIf="!isHoldingDataUnavailable">
            <button class="my-0 md:my-[10px]" (click)="getSnapshot();" data-cy="holdingSnapshot"><img
                src="{{iconSet['icon.snapshot']}}" />Snapshot</button>
          </div>
        </div>
        <app-info-text-box *ngIf="summary.dropdownPortfolioSeriesMasterId">
          <div class="text-center">
            This is a GCM Commingled product. For series level data, click
            <a routerLinkActive="active"
              routerLink="/investor/{{queryParamsInvestorId}}/series/{{summary.dropdownPortfolioSeriesMasterId}}"
              [queryParams]="{reportingdate: valReportingDate}" class="commingled-btn">
              here</a>.
          </div>
        </app-info-text-box>
        <app-error-card *ngIf="isHoldingDataUnavailable" data-cy="errorCard">
          <h3 class="card-title">Holding data for the selected reporting period <b>{{selectedAsOfDate | dateFormat :
              'MM/dd/yyyy'}}</b> is not available.</h3>
          <div>
            <div>Please return to the prior page or select a new date from the As of Date Dropdown.</div>
            <div>Click
              <a routerLinkActive="active" [routerLink]="breadcrumbs[0]?.route"
                (click)="breadcrumbClick(breadcrumbs[0])"
                [queryParams]="breadcrumbDataService.getQueryParamsForBreadcrumbs(breadcrumbs[0])" class="click-here-btn"
                data-cy="investorDashboardRedirect">
                here
              </a>
              to go back to dashboard.
            </div>
          </div>
        </app-error-card>
        <div class="card detail-card">
          <div class="mx-0 mb-2 lg:pl-0 text-5xl detail" *ngIf="isSingleAssetHolding">
            {{dealDetailLabel}}
          </div>
          <div class="row">
            <div class="col-7">
              <div class="row grid grid-cols-1 px-[15px] mt-4 md:mt-0" style="min-height: 42px">
                <div class="product-container__deal-name text-2xl md:text-4xl">
                  {{dealName}}
                </div>
              </div>
              <div class="row grid grid-cols-1 md:grid-cols-3 px-[15px]">
                <div class="col-span-1 md:col-span-2 pt-2">
                  <div class="product-container__holdings-title">
                    <div class="md:flex holding-title text-md md:text-lg" id="holdingName" data-cy="holdingTitle">
                      {{holdingTitle}}
                      <div class="breadcrumbAsOfDate m-0 mt-[3px] md:mt-[5px] p-0 md:pl-[5px] flex" data-cy="asOfDate">
                        as of
                        {{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}
                        <div *ngIf="summary.publishValuationType === 'Audit'" data-cy="auditPublished" class="final">
                          --
                          Final
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-7">
                  <img class="asset-image" [src]="assetClassImage[1]" [matTooltip]="assetClassImage[0]"
                    matTooltipClass="blue-tooltip" matTooltipPosition="right" data-cy="assetClassImage">
                </div>
              </div>
            </div>
            <div class="col-5" *ngIf="isSingleAssetHolding">
              <div class="mx-0 mb-4 lg:pl-0">
                <div class="lg:px-0">
                  <div *ngIf="assetSummary.logo" class="logo">
                    <img src="{{assetSummary.logo}}" data-cy="assetLogo">
                    <span *ngIf="assetSummary.showAssetUrl" class="text-md md:text-lg pl-4"><a
                        href="{{assetSummary.assetURL}}" target="_blank"
                        (click)="activityTracking.navigateToAssetUrl('single asset holding dashboard', assetMasterId, assets[0].name, assetSummary.assetURL)">{{assetSummary.assetURL}}</a></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-holding-dashboard-summary [iconSet]="iconSet" [cfmanagerLogo]="cfmanagerLogo"
            [holdingSummaryText]="holdingSummary" [holdingTitle]="holdingTitle"
            [holdingSummary]="summary"></app-holding-dashboard-summary>
          <!-- Hide the charts and asset table when we have single asset holdings -->
          <ng-container *ngIf="!isSingleAssetHolding">
            <div class="row mt-4 widget-row grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0">
              <div class="pl-0 pr-30" tourAnchor="holding.dashboard.sector">
                <app-sectors-widget [widgets]="widgets" [currency]="currency"></app-sectors-widget>
              </div>
              <div class="pl-0 pr-30" tourAnchor="holding.dashboard.geography">
                <app-geography-widget [widgets]="widgets" [currency]="currency"></app-geography-widget>
              </div>
            </div>
            <app-largest-position-widget [widgets]="widgets" [currency]="currency"></app-largest-position-widget>
            <app-assets-detail-table [portfolioName]="portfolioName" [seriesName]="seriesName"
              [asOfDate]="selectedAsOfDate" [holdingName]="holdingTitle" [assets]="assets"
              [currency]="currency"></app-assets-detail-table>
          </ng-container>
        </div>
        <!-- Show the asset summary when we have single asset holdings -->
        <ng-container *ngIf="isSingleAssetHolding">
          <div class="card detail-card">
            <div class="mx-0 mb-4 lg:pl-0 text-5xl detail">
              {{assetDetailLabel}}
            </div>
            <app-asset-summary [assetType]="assets[0]?.assetType" [assetData]="assets[0]" [title]="assetSummary.title"
              [summary]="assetSummary.summary" [logo]="assetSummary.logo" [showingOnHoldingDashboard]="true"
              [slidingImgs]="assetSummary.slidingImgs"></app-asset-summary>
            <!-- Will Add financial Summary widget here once the data is available for the same -->
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</div>